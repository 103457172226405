(function () {
    angular.module('informaApp')
        .component('newFeatureForm', {
            templateUrl: 'components/admin-sections/whats-new-section/new-feature-form/template.html',
            controller: NewFeatureForm,
            bindings: {
                model: '<',
                onCreatedEdited: '<'
            }
        });

    function NewFeatureForm(NewFeatureService, ToastrHelper) {
        this.progressPercent = null;

        this.$onInit = () => {
            this.isEdit = !!this.model;
            this.originModel = _.cloneDeep(this.model);

            if (!this.model) {
                this.model = {
                    preview: {
                        fileType: null,
                        file: null,
                        videoPreview: null,
                    },
                    title: '',
                    description: '',
                    attachments: []
                };
            }

            this.model.removedAttachments = [];
        };

        this.onFileChosen = (fileType, file, videoPreview) => {
            this.model.preview.fileType = fileType;
            this.model.preview.file = file;
            this.model.preview.videoPreview = videoPreview;
        };

        this.onAttachmentChosen = (attachment) => {
            if (attachment) {
                this.model.attachments.push(attachment);
            }
        };

        this.removeAttachment = (attachment) => {
            this.model.attachments = this.model.attachments.filter(x => x !== attachment);
        };

        this.removeExistingAttachment = (attachment) => {
            this.model.removedAttachments.push(attachment.id);
            this.removeAttachment(attachment);
        };

        this.createUpdateFeature = () => {
            if (validateModel(this.model)) {
                const onSuccess = () => {
                    this.progressPercent = null;
                    ToastrHelper.showSuccess('Successfully Created', {positionClass: 'toast-top-right'});

                    this.onCreatedEdited && this.onCreatedEdited();
                };

                const onFail = () => {
                    this.progressPercent = null;
                    ToastrHelper.showError('Something went wrong', {positionClass: 'toast-top-right'});
                };

                const onProgress = (data) => this.progressPercent = (data.loaded / data.total * 100).toFixed(0);

                if (this.isEdit) {
                    this.model.attachments = this.model.attachments.filter(x => x instanceof File);
                    NewFeatureService.update(getUpdateModel(this.model, this.originModel)).then(onSuccess, onFail, onProgress);
                } else {
                    NewFeatureService.create(this.model).then(onSuccess, onFail, onProgress);
                }
            }
        }
    }

    function getUpdateModel(model, originModel) {
        const result = _.cloneDeep(model);

        if (model.title === originModel.title) {
            delete result.title;
        }

        if (model.description === originModel.description) {
            delete result.description;
        }

        if (model.preview.videoPreview === originModel.preview.videoPreview) {
            delete result.preview.videoPreview;
        }

        if (model.preview.file === originModel.preview.file) {
            delete result.preview.file;
            delete result.preview.fileType;
        }

        return result;
    }

    function validateModel(model) {
        return model.preview.file && model.title;
    }
})();
